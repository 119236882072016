@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,900;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'F51';
  src: local('F51'), url(/static/media/F37F51-Light.0af37ad6.woff) format('woff');
}



@font-face {
  font-family: 'RoofTop';
  src: local('RoofTop'), url(/static/media/Rooftop-Regular.86b3c3a5.woff) format('woff');
}

body {
  text-align: center;
  font-family: RoofTop;
  background-color: #000000;
  color:#efefef;
  display:flex;
  justify-content: space-around;
  /* background-image: url("./ts_bg_white.png"); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: 150ch;
}
h2{
  font-family: RoofTop;
  margin: 10px;
}
.header>div{
  flex: 1 1 50%;
  display:flex;
}
ul {
  justify-content: flex-end;
  list-style-type: none;
  margin: 0 0 0 auto;
  padding: 0px;
  }
li {
  display: inline-block;
  margin: 0px 10px;    
  }
li > a {
  color: #838383;
  font-size: 18px;
}
h1{
  font-size: 5rem;
  font-weight: normal;
  margin-bottom: 12px;
}
p{
  color:#8e8e8e;
  font-size: 1.5rem;
}
.modal{
  position: absolute;
  min-width:400px;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  background-color:#1a1a1a;
  color:#efefef;
  border:0px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color:rgba(0,0,0,0.8);
}
.links{
  display:flex;
  flex-wrap: wrap;
  justify-content: center;
}
.links>a{
  margin-left: 1rem;
  margin-right: 1rem;
}
.socials{
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
}
.socials>a{
  margin: 10px;
}
.float-button{
  position: fixed;
  display: flex;
  justify-content: center;
  align-items:center;
  font-size: 10px;
  right: 0px;
  bottom: 0px;
  margin: 0 30px 30px 0;
  cursor: pointer;
  background-color: #333;
  border-radius: 18px;
  box-shadow: 0 0 2px #888;
  height: 30px;
  width: 30px;
}
.Token {
  margin: 10px;
  width:350px;
  height:350px;
  position: relative;
}
.Cover{
  position: absolute;
  top:0px;
  width:100%;
  height:100%;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.Overlay{ 
  margin:8px;
  font-size:12px;
  z-index: 1;
  position:absolute;
  color:#e1e1e1;
  top:0px;
  right:0px;
}
iframe{
  vertical-align: baseline;
  border: 0px;
  width : 350px;
  height : 350px;
  font-size: 36px;
  color: white;
  cursor:pointer;
}
.text{
  font-size: 0.75em;
  color:#efefef;
  margin:10px;
}
#bgvideo {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  background-color: black;
  opacity: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}
.mintstatus{
  margin: 24px;
}
a#txhash{
  font-size: 1em;
  color:#D4FF39;
  margin:10px;
}
.err-text{
  font-size: 0.5em;
  color:#ef4a4a;
  margin:10px;
}
input{
  margin-left: 10px;
  text-align: center;
  border: none;
}
input:focus{
  outline: none;
}
a{
  color:#efefef;
  font-size: 1.5rem;
  text-decoration: none;
}
a:hover{
  text-decoration: underline;
}
.content{
  display:block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 60vh;
  background-color: #000000;
  padding: 10px;
  width: 400px;
  max-width: 90%;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 600px) {
  .App {
    max-width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
  img{
    max-width: 90%;
  }
  iframe{
    width : 300px;
    height : 300px;
  }
  #bgvideo{
    display: none;
  }
  body {
    background-image: url(/static/media/ts_logo_embed.1be67e95.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100ch;
  }
}

input{
  font-family: RoofTop;
  border-radius: 8px;
  padding: 10px 20px 10px 20px;
  color: #D4FF39;
  margin: 1em;
  background-color: #000000;
  transition: 0.3s;
  text-transform: uppercase;
}
.phasebar{
  margin:10px;
}
.phasebar>span{
  margin:0px 10px 0px 10px;
  text-transform: uppercase;
  color:#8e8e8e


}

.phasebar>span#active{
  color: #D4FF39;
}

button{
  font-family: RoofTop;
  border-radius: 8px;
  border-color: #D4FF39;
  border: solid 2px;
  padding: 10px 20px 10px 20px;
  color: #D4FF39;
  background-color: #000000;
  transition: 0.3s;
  text-transform: uppercase;
}
button#small{
  padding: 5px 10px 5px 10px;
  border: none;
}
button:hover{
  background-color: #D4FF39;
  color: black;
  cursor: pointer;
}
input{
  width: 64px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.glitch {
  font-size: 5rem;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
  text-shadow: 0.05em 0 0 #00fffc, -0.03em -0.04em 0 #fc00ff,
    0.025em 0.04em 0 #fffc00;
  -webkit-animation: glitch 725ms infinite;
          animation: glitch 725ms infinite;
}

.pb-wrapper {
  width: 200px;
  margin: auto;
  padding: 10px;
}

.pb-progress-bar {
  width: 100%;
  border: 1px dotted #D4FF39;
}

.pb-progress-bar-fill {
  display: block;
  height: 8px;
  background-color: #D4FF39;
  transition: width 500ms ease-in-out;
}

.placeholder-center {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

.placeholder-center>img{
  max-width: 80%;
}
